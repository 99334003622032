import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MainSection } from "../components/main-section"
import { InfoBoxes } from "../components/info-boxes"
import { SmCategorySlider } from "../components/sm-category-slider"
import { MdCategorySlider } from "../components/md-category-slider"
import { CategorySlider } from "../components/category-slider"
import { ContactForm } from "../components/contact-form"
import { FaqComponent } from "../components/faq-body"

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
  query {
      allContentfulProduct(sort: { fields: title, order: DESC }) {
          edges{
              node{
                  slug
                  title
                  body {
                      body
                  }
                  category
                  images {
                    id
                    description
                    gatsbyImageData
                    url
                    title
                }
              }
          }
      },
      allContentfulHomePage {
        edges {
          node {
            section1HeaderText
            section1MainImage {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
              )
            }
            section1Image1 {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
              )
            }
            section1Image2 {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
              )
            }
            section1Image3 {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
              )
            }
            section1Image4 {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
              )
            }
            section2BulletPointsLine1
            section2BulletPointsLine2
            section2BulletPointsLine3
            section2BulletPointsLine4
            section2RightSideTitle
            section2RightSideBody {
              childMarkdownRemark {
                html
              }
            }
            faqQuestion1
            faqQuestion2
            faqQuestion3
            faqQuestion4
            faqAnswer1
            faqAnswer2
            faqAnswer3
            faqAnswer4
          }
      }
    }
  }
  `)

  return (
    <div>
      <Layout>
        <Seo
          title="Home - Iron West Group Affordable Heavy Equipment Sales"
          description="Iron West Group Specializes In Selling Affordable Heavy Equipment, ATV's, Commercial Equipment, Construction Equipment, Attachment's, And Much More."
          location={props.location.pathname}
        />
        <MainSection data={data.allContentfulHomePage} />
        <InfoBoxes data={data.allContentfulHomePage} />
        <div className="md:hidden">
          <SmCategorySlider data={data.allContentfulProduct} />
        </div>
        <div className="hidden md:block lg:hidden">
          <MdCategorySlider data={data.allContentfulProduct} />
        </div>
        <div className="hidden lg:block">
          <CategorySlider data={data.allContentfulProduct} />
        </div>
        <div className="pt-10 pb-12 px-5">
          <p className="text-center font-bold text-2xl pb-8">Have Any Questions or Comments?</p>
          <ContactForm formName="Home Page" />
        </div>
        <FaqComponent data={data.allContentfulHomePage} />
      </Layout>
    </div >
  )
}

export default IndexPage