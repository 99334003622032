import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export class MainSection extends Component {
    render() {
        const data = this.props.data.edges
        const homePage = data[0].node

        return (
            <div>
                <div className="relative container mx-auto">
                    <div>
                        <div className="text-white absolute z-10 inset-x-0 px-10 py-20 xl:pt-36 text-center lg:text-left">
                            <div className="flex justify-center">
                                <h1 className="font-bold text-5xl md:text-6xl lg:text-7xl lg:max-w-2xl xl:max-w-3xl xl:pb-5">
                                    {homePage.section1HeaderText}
                                </h1>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 pt-10 gap-y-9 gap-x-10  md:gap-y-14 font-bold">

                                <div className="w-[8rem] h-[8rem] md:w-full md:h-full rounded-2xl bg-white mx-auto">
                                    <Link to="/inventory" title="Heavy Equipment - Iron West Group" alt="Heavy Equipment - Iron West Group" >
                                        <GatsbyImage image={homePage.section1Image1.gatsbyImageData} className="rounded-2xl z-10 h-full hover:opacity-80 transition duration-150 ease-in-out" loading="eager" placeholder="none" title={homePage.section1Image1.title} alt={homePage.section1Image1.title} />
                                        <p className="text-xs md:text-base text-orange text-center uppercase pt-2 hover:text-white transition duration-150 ease-in-out">{homePage.section1Image1.description}</p>
                                    </Link>
                                </div>

                                <div className="w-[8rem] h-[8rem] md:w-full md:h-full rounded-2xl bg-white mx-auto">
                                    <Link to="/inventory" title="Attachments - Iron West Group" alt="Attachments - Iron West Group" >
                                        <GatsbyImage image={homePage.section1Image2.gatsbyImageData} className="rounded-2xl z-10 h-full hover:opacity-80 transition duration-150 ease-in-out" loading="eager" placeholder="none" title={homePage.section1Image2.title} alt={homePage.section1Image2.title} />
                                        <p className="text-xs md:text-base text-orange text-center uppercase pt-2 hover:text-white transition duration-150 ease-in-out">{homePage.section1Image2.description}</p>
                                    </Link>
                                </div>



                                <div className="w-[8rem] h-[8rem] md:w-full md:h-full rounded-2xl bg-white mx-auto">
                                    <Link to="/inventory" title="Tracks & Tires - Iron West Group" alt="Tracks & Tires - Iron West Group" >
                                        <GatsbyImage image={homePage.section1Image3.gatsbyImageData} className="rounded-2xl z-10 h-full hover:opacity-80 transition duration-150 ease-in-out" loading="eager" placeholder="none" title={homePage.section1Image3.title} alt={homePage.section1Image3.title} />
                                        <p className="text-xs md:text-base text-orange text-center uppercase pt-2 hover:text-white transition duration-150 ease-in-out">{homePage.section1Image3.description}</p>
                                    </Link>
                                </div>

                                <div className="w-[8rem] h-[8rem] md:w-full md:h-full rounded-2xl bg-white mx-auto">
                                    <Link to="/inventory" title="Parts - Iron West Group" alt="Parts - Iron West Group" >
                                        <GatsbyImage image={homePage.section1Image4.gatsbyImageData} className="rounded-2xl z-10 h-full hover:opacity-80 transition duration-150 ease-in-out" loading="eager" placeholder="none" title={homePage.section1Image4.title} alt={homePage.section1Image4.title} />
                                        <p className="text-xs md:text-base text-orange text-center uppercase pt-2 hover:text-white transition duration-150 ease-in-out">{homePage.section1Image4.description}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GatsbyImage image={homePage.section1MainImage.gatsbyImageData} className="w-screen h-[600px] md:h-[800px] lg:h-[550px] xl:h-[760px]" loading="eager" placeholder="none" title={homePage.section1MainImage.title} alt={homePage.section1MainImage.title} />
            </div>
        )
    }
}