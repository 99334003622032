import React, { Component } from "react"
// import { OrangeButton } from "./button-orange"
import Arrows from "../icons/arrows"

export class InfoBoxes extends Component {
    render() {
        const data = this.props.data.edges
        const homePage = data[0].node
        return (
            <div className="banner-gradient bg-orange">
                <div className="container mx-auto">
                    <div className="grid lg:grid-cols-2">
                        <div className="px-5 py-10 space-y-10 md:space-y-12 mx-auto ">
                            <div>
                                <div className="flex justify-start items-center">
                                    <Arrows />
                                    <span className="pl-2 text-2xl lg:text-3xl font-semibold">{homePage.section2BulletPointsLine1}</span>
                                </div>
                            </div>

                            <div>
                                <div className="flex justify-start items-center">
                                    <Arrows />
                                    <span className="pl-2 text-2xl lg:text-3xl font-semibold">{homePage.section2BulletPointsLine2}</span>
                                </div>
                            </div>

                            <div>
                                <div className="flex justify-start items-center">
                                    <Arrows />
                                    <span className="pl-2 text-2xl lg:text-3xl font-semibold">{homePage.section2BulletPointsLine3}</span>
                                </div>
                            </div>

                            <div>
                                <div className="flex justify-start  items-center">
                                    <Arrows />
                                    <span className="pl-2 text-2xl lg:text-3xl font-semibold">{homePage.section2BulletPointsLine4}</span>
                                </div>
                            </div>

                        </div>

                        <div className="bg-bodygray items-center hidden lg:flex">
                            <div className="p-10 text-left">
                                <h3 className="font-semibold text-3xl">{homePage.section2RightSideTitle}</h3>
                                <div className="py-5 max-w-4xl text-xl" dangerouslySetInnerHTML={{
                                    __html: homePage.section2RightSideBody.childMarkdownRemark.html,
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-bodygray items-center flex lg:hidden">
                    <div className="px-5 py-5 md:py-10 text-center mx-auto">
                        <h3 className="font-semibold text-2xl">{homePage.section2RightSideTitle}</h3>
                        <div className="py-5 max-w-4xl" dangerouslySetInnerHTML={{
                            __html: homePage.section2RightSideBody.childMarkdownRemark.html,
                        }}>
                        </div>
                    </div>
                </div>
                {/* <div className="bg-darkgray">
                    <div className="text-white text-center py-5 lg:py-10 pb-7">
                        <h3 className="font-semibold text-2xl lg:text-3xl pb-3">Browse Our Current Inventory</h3>
                        <OrangeButton buttonPage="/inventory" buttonText="Browse Inventory" />
                    </div>
                </div> */}
                <div className="bg-darkgray h-1"></div>
            </div>
        )
    }
}