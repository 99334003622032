import React, { Component } from "react"
import { Link } from "gatsby"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class OrangeButton extends Component {
    render() {
        return (
            <div>
                <Link to={this.props.buttonPage} title={this.props.buttonTitle} alt={this.props.buttonAlt}>
                    <button className="relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out">
                        <span>{this.props.buttonText}</span>
                    </button>
                </Link>
            </div >
        )
    }
}