import React, { Component } from 'react'
import Faq from 'react-faq-component'

const styles = {
    bgColor: "none",
    rowTitleColor: "black",
    rowContentColor: "#000000",
    arrowColor: "black",
    rowContentPaddingTop: "1%",
    rowContentPaddingBottom: "2%",
}

export class FaqComponent extends Component {
    render() {
        const data = this.props.data.edges
        const faqPage = data[0].node

        const faqdata = {
            rows: [
                {
                    title: faqPage.faqQuestion1,
                    content: faqPage.faqAnswer1
                },
                {
                    title: faqPage.faqQuestion2,
                    content: faqPage.faqAnswer2
                },
                {
                    title: faqPage.faqQuestion3,
                    content: faqPage.faqAnswer3
                },
                {
                    title: faqPage.faqQuestion4,
                    content: faqPage.faqAnswer4
                }]
        }

        return (
            <div className="bg-lightgray">
                <p className="text-center font-semibold text-3xl pt-10 px-5 pb-6">Frequently Asked Questions</p>
                <hr className="border-orange max-w-xl mx-auto" />
                <div className="container mx-auto flex justify-center">
                    <div className="lg:w-3/4 xl:w-1/2 mx-auto pt-5 pb-16 px-5">
                        <Faq data={faqdata} styles={styles} />
                    </div>
                </div>
            </div>
        )
    }
}