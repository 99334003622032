import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { OrangeButton } from "./button-orange"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export class MdCategorySlider extends Component {
    render() {
        const data = this.props.data.edges

        let reSize = false
        let numOfSlides = 3
        if (data.length === 1) {
            numOfSlides = 1
            reSize = true
        }
        else if (data.length === 2) {
            numOfSlides = 2
        }
        else if (data.length < 1) {
            numOfSlides = 0
        }

        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            speed: 900,
            slidesToShow: numOfSlides,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            autoplay: true,
            swipe: true,
        };

        function shorten(str, maxLen, separator = '') {
            if (str.length <= maxLen) return str;
            return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
        }

        return (
            <div className="bg-bodygray">
                <div className="">
                    <div className="px-5 py-5 md:py-10 text-center">
                        <div className="max-w-5xl mx-auto">
                            <h3 className="font-bold text-3xl text-center">View Our Products</h3>
                            <div className="py-8">
                                <Slider {...settings}>
                                    {data && data.map(({ node: product }) => {
                                        return (
                                            <div className={`px-5 ${reSize ? 'max-w-[15rem]' : ''}`} key={product.slug}>
                                                <Link className="hover:text-orange hover:opacity-75 transition duration-100 ease-in-out" to={'/inventory' + product.slug}>
                                                    <GatsbyImage className="rounded-lg z-10 max-h-40 mx-auto" image={product.images[0].gatsbyImageData} loading="eager" title={product.images[0].title} alt={product.images[0].description} />
                                                    <p className="pt-2">{shorten(product.title, 70)}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                        <OrangeButton buttonPage="/inventory" buttonText="Browse Inventory" buttonTitle='Browse Inventory' buttonAlt='Iron West Group - Browse Inventory' />
                    </div>
                </div>
            </div>
        );
    }
}